import React from 'react';
import { FaInstagram, FaSnapchat, FaEnvelope, FaTiktok } from 'react-icons/fa';

const Footer = () => {
  const instagramUrl = 'https://www.instagram.com/elev_8.shop';
  const snapchatUrl = 'https://www.tiktok.com/elev8uae';
  const emailUrl = 'mailto:Elev8uae@gmail.com';

  const iconStyle = {
    fontSize: '20px', // Adjust the icon size
    marginRight: '5px', // Adjust the space between icons
  };

  const circleStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    width: '30px', // Adjust the width of the circle
    height: '30px', // Adjust the height of the circle
    borderRadius: '50%',
    background: 'lightgray',
    paddingLeft:'5px',
    marginRight: '10px', // Adjust the space between circles
  };

  return (
    <div className='main_footer'>
    <footer>
      <p>&copy; 2023 Copyright elev8</p>
      <div>
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
          <div style={{ ...circleStyle, background: '#404140' }}>
            <FaInstagram size={16} color="#898a89" style={iconStyle} />
          </div>
        </a>
        <a href={snapchatUrl} target="_blank" rel="noopener noreferrer">
          <div style={{ ...circleStyle, background: '#404140' }}>
            <FaTiktok size={16} color="#898a89" style={iconStyle} />
          </div>
        </a>
        <a href={emailUrl} target="_blank" rel="noopener noreferrer">
          <div style={{ ...circleStyle, background: '#404140' }}>
            <FaEnvelope size={15} color="#898a89"  style={iconStyle} />
          </div>
        </a>
      </div>
    </footer></div>
  );
};

export default Footer;

// src/FloatingActionButton.js
import React from 'react';
import '../styles/footer.css';
import whatsappIcon from '../assets/WhatsApp-Logo.png'

function FloatingActionButton() {
  return (
    <div className="whatsapp-fab">
      <a
        href="https://web.whatsapp.com/send?phone=YOUR_PHONE_NUMBER"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={whatsappIcon}
          height={50}
          width={50}
          alt="WhatsApp"
          className="whatsapp-icon"
        />
      </a>
    </div>
  );
}

export default FloatingActionButton;

import React, { useRef } from "react";
import { Accordion, Card } from 'react-bootstrap';
import "../styles/pages.css";
import { SocialIcon } from 'react-social-icons';
import { FaEnvelope } from 'react-icons/fa';

function FAQ() {
  // Initialize a ref for the Accordion
 

  return (
    <div className="body-main">
      <div>
        <div className="header-shop">
          <h1>
            <b> FAQ </b>
          </h1>
        </div>
        <div className="intro-faq">
          <h3><b>Frequently Asked Questions</b></h3>
          <p>Welcome to our Frequently Asked Questions (FAQ) page, where we aim to address common queries and provide you with clear and concise answers. Whether you're seeking information about our products, services, or general inquiries, this section is designed to enhance your experience by offering swift solutions to the most commonly asked questions.</p>
          
          <div className="question-section">
          <h6><b>1- When will I recieve my order?</b></h6>
          <h6>The anticipated timeframe for the receipt of the item is within 5 to 10 business days.</h6>
          </div>
          <div className="question-section">
          <h6><b>2- Can I return/exchange any order?</b></h6>
          <h6>Once an order is placed, we cannot accommodate exchanges or cancellations due to the customized nature of the product, tailored specifically to your request and unsuitable for resale to others.</h6>
          </div>
    
          <div className="question-section">
          <h6><b>3- Can I customize my own hoodie/shirt?</b></h6>
          <h6>Certainly, you have the option to customize your clothing pieces according to your preferences and liking.</h6>
          </div>
       
          <div className="question-section">
          <h6><b>4- How can I pay?</b></h6>
          <h6>You can pay using bank transfer.</h6>
          </div>
          
          <div className="question-section">
          <h6><b>5- What are the shipping costs?</b></h6>
          <h6>Shipping charges vary based on your preferred location. Please contact us for more information regarding the applicable shipping costs.</h6>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;

import React from "react";
import "../styles/pages.css";
import jacket from "../assets/elev8_jacket.JPG";
import tote from "../assets/tote.JPG";
import caps from "../assets/caps.JPG";
import shirts from "../assets/shirts.JPG";
import qualityIcon from "../assets/quality.png";
import paletteIcon from "../assets/color-circle.png";
import deliverIcon from "../assets/fast-delivery.png";

function Shop() {
  return (
    <body className="body-main">
      <div className="header-shop">
        <h1>
          <b> Our Product Offerings </b>
        </h1>
      </div>
      <div className="offered-products-jackets">
      <div className="jacket">
  <img src={jacket} alt="WhatsApp" className="shop_us_img" />
  
  <h4 className="product-names"><b>Varsity Jackets & Sweat Shirts</b></h4>
  <div className="icon-container" style={{float:"right"}}>
    <img src={paletteIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available in multiple colors</p>
  </div>
  <div className="icon-container" style={{float:"right"}}>
    <img src={deliverIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available GCC Shipping</p>
  </div>
</div>


<div className="jacket1">
  <img src={tote} alt="WhatsApp" className="shop_us_img" />
  <h4 className="product-names"><b>Customizable Tote Bags</b></h4 >
  <div className="icon-container" style={{float:"right"}}>
    <img src={paletteIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available in multiple colors</p>
  </div>
  <div className="icon-container" style={{float:"right"}}>
    <img src={deliverIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available GCC Shipping</p>
  </div>
</div>



<div className="jacket">
  <img src={caps} alt="WhatsApp" className="shop_us_img" />
  <h4 className="product-names"><b>Caps</b></h4>
  <div className="icon-container" style={{float:"right"}}>
    <img src={paletteIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available in multiple colors</p>
  </div>
  <div className="icon-container" style={{float:"right"}}>
    <img src={deliverIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available GCC Shipping</p>
  </div>
</div>


<div className="jacket1">
  <img src={shirts} alt="WhatsApp" className="shop_us_img" />
  <h4 className="product-names"><b>T-Shirts</b></h4>
  <div className="icon-container" style={{float:"right"}}>
    <img src={paletteIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available in multiple colors</p>
  </div>
  <div className="icon-container" style={{float:"right"}}>
    <img src={deliverIcon} className="icon-shop" alt="palette icon" />
    <p>&nbsp;&nbsp;Available GCC Shipping</p>
  </div>
</div>


   
      </div>
    </body>
  );
}

export default Shop;

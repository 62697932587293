import React from 'react'
import '../styles/pages.css'
import designImage from '../assets/design_team.jpg'
import seniorJacket from '../assets/senior_jacket_img.png'
import jersey from '../assets/jersey.png';
import clothesRange from '../assets/clothes_range.jpg';

function AboutUs(){
    return(
        <body className ="body-main">
        <div className="about-us-section">
            <p className = "heading"> About Us </p>
            <p className="intro-text"> Elev8: The perfect blend of fashion and personalization</p>
        </div>

        <div className='info-section'>
            <h3 >Welcome to <b>Elev8</b></h3>
            <div className='card-1'>
            <img
          src={jersey} alt="WhatsApp"
          className="about_us_img"
  
        /> 
            <p><b>Tailored Excellence for Your Brand</b></p>
            <p id="intro-text">At Elev8, we pride ourselves on our commitment to creating tailored uniforms that go beyond mere attire—they reflect the very essence of your brand. </p>
            </div>
        
       
        <div className='card-1'>
            <img
          src={clothesRange} alt="WhatsApp"
          className="about_us_img"
         
        /> 
            <p><b>Casual Comfort, Stylish Statements</b></p>
            <p>Dive into the world of casual chic with our range of comfortable t-shirts and trendy hoodies. Elev8 doesn't just offer clothing; we present a canvas for you to express your individual style.  </p>
            </div>


            <div className='card-1'>
            <img
          src={seniorJacket} alt="Senior Jackets"
          className="about_us_img"
         
        /> 
            <p><b> Senior Jackets Like Never Before</b></p>
            <p>Our senior jackets are crafted with precision, reflecting not only the warmth and camaraderie of your school community but also your individual achievements and memories. </p>
            </div>
           
            <div className="design-section">
                <h3 style={{paddingTop:'20px'}}><b>Creativity Meets Quality: The Elev8 Experience</b></h3>
                <img
          src={designImage} alt="WhatsApp"
        
        /> 
        <p className="design-text">At the heart of Elev8 lies a dedicated team of experts who blend creativity with quality to bring your fashion vision to life. Our designers are not just skilled craftsmen; they are artists who understand the power of clothing as a form of self-expression. Elev8 is here to be a part of your narrative, crafting pieces that resonate with who you are and what you stand for.</p>
            </div>
        
    
</div>
        </body>
    );
}

export default AboutUs
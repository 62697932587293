import React,  { useState } from 'react'
import "../styles/navbar.css";
import {Link} from 'react-router-dom';
import elev8Icon from '../assets/elev8.png'



// function Navbar() {
//   return (
//     <div class = "navbar">
//       <div className = "leftSide">
//       <img
//           src={elev8Icon} alt="WhatsApp"
//           className="main-icon"
//           height={'70px'}
//         /> 
//       </div>
//       <div className = "rightSide">
      
//       <a href = '/'>About Us</a>
//       <a href = '/'>Shop</a>
//       <a href = '/'>FAQ</a>
//       <a href = '/'>Corporate Offerings</a>
//       <a href = '/'>Contact Us</a>
//       </div>
      
     
//     </div>
//   )
// }

// export default Navbar



const Navbar = props => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky">
      <div className = "leftSide">
      <a class="navbar-brand text-info font-weight-bolder" href="/">
      
      <img
          src={elev8Icon} alt="WhatsApp"
          className="main-icon"
          height={'50px'}
        /> 
       
      </a>
      </div>
      <button class="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
        <span class="navbar-toggler-icon"></span>
      </button>
     
      <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
      <div className = "rightSide">
        <a className="nav-link" href="/aboutus" >About Us</a>
        <a className="nav-link" href="/shop">Shop</a>
        <a className="nav-link" href="/FAQ">FAQ</a>
        <a className="nav-link" href="/contactus">Contact Us</a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

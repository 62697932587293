// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from './components/navbar.js';
import AboutUs from './pages/about_us.js';
import Shop from './pages/shop.js';
import MailChimpForm from './pages/new_Contact_us.js';
import FAQ from './pages/faq.js';
import Footer from './components/footer.js';
import FloatingActionButton from './components/floating';

function App() {
  return (
 
      <div className="App">
        <Navbar/>
        <Routes>
        <Route path="/" element={<AboutUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/FAQ" element={<FAQ />} />
          <Route path="/contactus" element={< MailChimpForm/>} />
        </Routes>

      <FloatingActionButton/>
        <footer>
          <Footer />
        </footer>
      </div>

  );
}
export default App; 

import "../styles/pages.css";
import React, { useState } from "react";
import "../styles/pages.css";
import contactIcon from "../assets/coontact_us.png";

function MailchimpForm() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleFirstNameChange = (e) => setFirstName(e.target.value);
  const handleLastNameChange = (e) => setLastName(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);
  return (
    <body className="body-main">
      <div id="mc_embed_shell">
        <div>
          {" "}
          <img src={contactIcon} alt="WhatsApp" className="contact-icon" />{" "}
        </div>
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />

        <div id="mc_embed_signup">
          <form
            action="https://gmail.us21.list-manage.com/subscribe/post?u=8706192aaa4699f810096c924&amp;id=30b83e66a9&amp;f_id=00d3e4e6f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <h2>Send Us a Message</h2>
              <div class="indicates-required">
                <span class="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">
                  First Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="FNAME"
                  className="required text"
                  id="mce-FNAME"
                  value={firstName}
                  onChange={handleFirstNameChange}
                  required
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">
                  Last Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="LNAME"
                  className="required text"
                  id="mce-LNAME"
                  value={lastName}
                  onChange={handleLastNameChange}
                  required
                />
              </div>

              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE6">
                  Message <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  name="MMERGE6"
                  className="required text"
                  id="mce-MMERGE6"
                  value={message}
                  onChange={handleMessageChange}
                  required
                />
              </div>

              <div id="mce-responses" class="clear foot">
                <div class="response" id="mce-error-response"></div>
                <div class="response" id="mce-success-response"></div>
              </div>

              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                value="Send Message"
                style={{ heigh: "50px" }}
              ></input>
            </div>
          </form>
        </div>
      </div>
    </body>
  );
}

export default MailchimpForm;
